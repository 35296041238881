import { Button, Flex, Heading, Text } from "@radix-ui/themes";
import React, { useEffect, useState } from "react";
import Categories from "../elements/Categories";
import Dishes from "../elements/Dishes";
import MainDivTemplate from "../elements/MainDivTemplate";
import FloatingWhastappButton from "../elements/FloatingWhastappButton";
import Shrimp1 from "../images/Shrimp.svg";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../firebase/FirebaseConfig";

const Menu = () => {
  const [selectedCategory, setCategory] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(() => {
    const fetchMenu = async () => {
      try {
        const pdfRef = ref(storage, "/menu/MENU.pdf");
        const url = await getDownloadURL(pdfRef);
        setPdfUrl(url);
      } catch (error) {
        console.log(error);
      }
    };

    fetchMenu();
  },[pdfUrl])

  return (
    <div>
      <FloatingWhastappButton />
      <MainDivTemplate bgStart="from-white" bgFinish="to-white" grid={true}>
        <div className="col-start-2 p-8 md:col-span-2 md:m-12">
          <div className=" flex flex-col gap-3">
            <Heading
              size={{
                initial: "6",
                sm: "9",
              }}
              align="center"
              color="violet"
              mb="5"
            >
              Nuestros Platillos{" "}
            </Heading>
          </div>
          <Flex gap="3" direction="column" justify="center">
            <Categories
              selectedCategory={selectedCategory}
              setCategory={setCategory}
            />
            {selectedCategory ? (
              <div className="mt-2 rounded bg-white p-4">
                <Heading size="4">
                  Lo más pedido de{" "}
                  <Text color="violet">{selectedCategory}</Text>
                </Heading>
                <div className="mt-8 grid grid-cols-1 grid-rows-1 justify-items-center gap-4 rounded bg-white p-1 shadow-xl md:grid-cols-3 md:grid-rows-2 md:p-2">
                  <Dishes selectedCategory={selectedCategory} />
                </div>
              </div>
            ) : (
              <div className="mt-16 hidden w-fit self-center rounded-full bg-white p-2 md:block">
                <img src={Shrimp1} alt="shrimp" />
              </div>
            )}
          </Flex>
        </div>
        <div className="col-start-1 m-12 p-8 md:col-span-1">
          <div className="sticky top-[14rem] flex flex-col gap-3">
            <Heading>Conoce los platillos más solicitados</Heading>
            <Text>También puedes descargar nuestro menú</Text>
            <Button
              onClick={() =>
                window.open(pdfUrl, "_blank")
              }
            >
              Descarga el menú
            </Button>
          </div>
        </div>
      </MainDivTemplate>
    </div>
  );
};

export default Menu;
