import React from 'react'
import Home from "./components/Home"

const App = () => {
  return (
    <div>
      <Home />
    </div>
  )
}

export default App
