import Shrimp1 from "./../images/Shrimp.svg";
import Shrimp4 from "./../images/ShrimpTwo.svg";
import Shrimp3 from "./../images/ShrimpThree.svg";
import Shrimp2 from "./../images/ShrimpFour.svg";

const ShrimpLayout = () => {
  return (
    <div>
      <img
        className=" fixed left-11 top-[450px] -z-50 w-[150px] opacity-80 "
        src={Shrimp1}
        alt="shrimp"
      />
      <img
        className=" fixed left-[-40px] top-[-10px] -z-50 w-[250px] opacity-80"
        src={Shrimp2}
        alt="shrimp"
      />
      <img
        className=" fixed right-[0px] top-[30px] -z-50 w-[200px] opacity-80"
        src={Shrimp3}
        alt="shrimp"
      />
      <img
        className="fixed right-[0px] top-[20rem] -z-50 w-[250px] opacity-80"
        src={Shrimp4}
        alt="shrimp"
      />
    </div>
  );
};


export default ShrimpLayout;
