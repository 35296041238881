import {
  Button,
  Heading,
  Separator,
  Text
} from "@radix-ui/themes";
import React from "react";
import { BsFillTelephoneFill, BsMessenger, BsWhatsapp } from "react-icons/bs";
import ContactForm from "../elements/ContactForm";
import MainDivTemplate from "../elements/MainDivTemplate";
import sendMessenger from "../function/sendMessenger";
import sendWhastapp from "../function/sendWhastapp";
import ContactImg from "../images/contact.svg";

const Contact = () => {
  return (
    <MainDivTemplate bgStart="from-white" bgFinish="to-white" grid={true}>
      <div className="col-span-2 flex flex-col justify-start gap-3 p-8">
        <Heading
          size={{
            initial: "6",
            sm: "9",
          }}
          color="violet"
          mb="5"
        >
          Queremos escucharte
        </Heading>
        <Text>Haznos saber tus opiniones, quejas o sugerencias:</Text>
        <ContactForm />
        <Separator my="3" size="6" />
        <div className="flex flex-col gap-3 md:max-w-[30%]">
          <Text>O bien contactanos por:</Text>
          <Button
            onClick={() => {
              window.location.href = "tel:6691938973";
            }}
            color="orange"
          >
            <BsFillTelephoneFill />
            6691938973
          </Button>
          <Button onClick={() => sendWhastapp({ message: "" })} color="green">
            <BsWhatsapp />
            Whastapp
          </Button>
          <Button onClick={() => sendMessenger({ message: "" })} color="blue">
            <BsMessenger />
            Messenger
          </Button>
        </div>
      </div>
      <div className="col-span-1 flex items-center justify-center p-8">
        <img src={ContactImg} alt="contacto" />
      </div>
    </MainDivTemplate>
  );
};

export default Contact;
