import React from "react";
import { Route, Routes } from "react-router-dom";
import Contact from "../components/Contact";
import Facturacion from "../components/Facturacion";
import Menu from "../components/Menu";
import PrivacyPolicy from "../components/PrivacyPolicy";
import App from "../App";

const RouteProvider = () => {
  return (
    <Routes>
      <Route path="*" element={<App />} />
      <Route path="/" element={<App />} />
      <Route path="/menu" element={<Menu />} />
      <Route path="/aviso_de_privacidad" element={<PrivacyPolicy />} />
      <Route path="/contacto" element={<Contact />} />
      <Route path="/factura" element={<Facturacion />} />
    </Routes>
  );
};

export default RouteProvider;
