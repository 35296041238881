import { Button, Flex } from "@radix-ui/themes";
import { useState } from "react";
import { FaShrimp } from "react-icons/fa6";
import Logo from "../images/logo_mariscos.png";
import DesktopNavMenu from "./DesktopNavbarMenu";
import MobileNavMenu from "./MobileNavMenu";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate()

  return (
    <nav className="fixed top-0 z-50 px-8 bg-white py-4 md:px-24 md:py-4 w-full border border-b-2 flex justify-between shadow-xl">
      <Flex align="center" gap="5">
        <img onClick={() => navigate("/")} className="w-[80px] cursor-pointer" src={Logo} alt="logo" width={50} height={50} />
        <h1 onClick={() => navigate("/")} className="font-medium cursor-pointer hidden md:block">Mariscos La Unión</h1>
      </Flex>
      {/*Desktop menu */}
      <DesktopNavMenu />

      {/*Mobile menu */}
      <div className="relative md:hidden">
        <Button size='4' onClick={() => setOpen(!isOpen)} variant="soft">
          <FaShrimp /> Menú
        </Button>
      </div>
      {isOpen && <MobileNavMenu setOpen={setOpen} />}
    </nav>
  );
};

export default Navbar;
