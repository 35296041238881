import React from "react";
import { Flex } from "@radix-ui/themes";
import {
  AiFillInstagram,
  AiFillFacebook,
  AiFillGoogleCircle,
  AiFillYoutube,
} from "react-icons/ai";
import { FaTiktok } from "react-icons/fa";

const FooterSocials = () => {
  return (
    <Flex
      justify="center"
      align="center"
      gap={{
        initial: "2",
        sm: "4",
      }}
    >
      <a
        href="https://instagram.com/mariscos_la_union?utm_source=qr&igshid=MThlNWY1MzQwNA=="
        target="_blank"
        rel="noreferrer"
      >
        <AiFillInstagram size={30} />
      </a>
      <a
        href="https://www.facebook.com/mariscounion"
        target="_blank"
        rel="noreferrer"
      >
        <AiFillFacebook size={30} />
      </a>
      <a
        href="https://www.tiktok.com/@mariscoslaunionoficial?lang=es&is_from_webapp=1&sender_device=mobile&sender_web_id=7293398387100403206"
        rel="noreferrer"
        target="_blank"
      >
        <FaTiktok size={30} />
      </a>
      <a href="https://g.co/kgs/QA3uhw" target="_blank" rel="noreferrer">
        <AiFillGoogleCircle size={30} />
      </a>
      <a
        href="https://www.youtube.com/@MARISCOSLAUNION"
        target="_blank"
        rel="noreferrer"
      >
        <AiFillYoutube size={30} />
      </a>
    </Flex>
  );
};

export default FooterSocials;
