import { Theme } from "@radix-ui/themes";
import "@radix-ui/themes/styles.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import ShrimpLayout from "./components/ShrimpLayout";
import "./index.css";
import RouteProvider from "./routes/RouteProvider";

const Index = () => {
  return (
    <BrowserRouter>
      <Theme appearance="light" accentColor="violet">
        <RouteProvider />
        <Navbar />
        <ShrimpLayout />
        <Footer />
      </Theme>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Index />);
