import React from "react";
import NavLink from "../elements/NavLink";

const DesktopNavMenu = () => {
  const links = [
    { label: "Home", href: "/" },
    { label: "Menú", href: "/menu" },
    {
      label: "Contacto",
      href: "/contacto",
    },
    {
      label: "Factura",
      href: "/factura",
    },
  ];

  function setOpen(){
    //prevent mobile menu bug
  }
  return (
    <div className="items-center hidden  md:flex">
      <div className="flex gap-3">
        {links.map((link) => (
          <NavLink setOpen={setOpen} link={link} key={link.label}>
            {link.label}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default DesktopNavMenu;
