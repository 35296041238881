import React from "react";
import { useNavigate } from "react-router-dom";

const NavLink = ({ children, link, setOpen }) => {
  const navigate = useNavigate();
  return (
    <button
      onClick={() => {
        navigate(`${link.href}`);
        setOpen(false);
        window.scroll(0,0);
      }}
      className="text-violet-700 hover:underline"
    >
      {children}
    </button>
  );
};

export default NavLink;
