import { Card, Inset, Strong, Text } from "@radix-ui/themes";
import { collection, getDocs } from "firebase/firestore";
import { React, useEffect, useState } from "react";
import { db } from "../firebase/FirebaseConfig";

const Dishes = ({ selectedCategory }) => {
  const [categories, setCategories] = useState({
    "Barra Fría": [],
    "Barra Caliente": [],
    Bebidas: [],
    Entradas: [],
  });

  useEffect(() => {
    const fetchData = async (category, collectionName) => {
      try {
        const colRef = collection(db, collectionName);
        const querySnapshots = await getDocs(colRef);
        const data = querySnapshots.docs.map((doc) => doc.data());
        setCategories((prevCategories) => ({
          ...prevCategories,
          [category]: data,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData("Barra Fría", "barra_fria");
    fetchData("Barra Caliente", "barra_caliente");
    fetchData("Bebidas", "bebidas");
    fetchData("Entradas", "entradas");
  }, []);

  if (!selectedCategory || categories[selectedCategory].length === 0) return null;

  return categories[selectedCategory]
    .filter((dish) => dish.nombre && dish.descripcion)
    .map((dish, index) => (
      <Card key={index} size="2">
        <Inset clip="padding-box" side="top" pb="current">
          <img
            src={dish.imagen}
            alt={dish.nombre}
            style={{
              display: "block",
              objectFit: "cover",
              width: "100%",
              height: 140,
              backgroundColor: "var(--gray-5)",
            }}
          />
        </Inset>
        <Strong>{dish.nombre}</Strong>
        <Text as="p" size="3">
          {dish.descripcion}
        </Text>
      </Card>
    ));
};

export default Dishes;
