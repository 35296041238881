import NavLink from "../elements/NavLink";

const MobileNavMenu = ({ setOpen }) => {
  const links = [
    { label: "Home", href: "/" },
    { label: "Menú", href: "/menu" },
    {
      label: "Contacto",
      href: "/contacto",
    },
    {
      label: "Factura",
      href: "/factura",
    },
  ];
  return (
    <div className="absolute z-50 shadow-lg bg-gradient-to-br from-white to-slate-100 p-2 rounded right-8 w-[50%] top-14">
      <ul className="flex text-center flex-col gap-3">
        {links.map((link) => (
          <NavLink
            link={link}
            setOpen={setOpen}
            key={link.label}
          >
            {link.label}
          </NavLink>
        ))}
      </ul>
    </div>
  );
};

export default MobileNavMenu;
