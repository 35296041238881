import React from "react";
import MainContent from "../_content/MainContent";
import SecondaryContent from "../_content/SecondaryContent";
import MainDivTemplate from "../elements/MainDivTemplate";

const Home = () => {
    return (
        <MainDivTemplate
          bgStart="from-red-400"
          bgFinish="to-yellow-300"
          grid={true}
        >
          <div className="col-span-2 p-8 md:p-12">
            <MainContent />
          </div>
          <div className=" col-span-1 p-8 md:p-12">
            <SecondaryContent />
          </div>
        </MainDivTemplate>
      );
}

export default Home
