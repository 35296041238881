import { Button, Heading, TextField, Callout } from "@radix-ui/themes";
import React, { useState } from "react";
import MainDivTemplate from "../elements/MainDivTemplate";
import Billing from "../images/Billing.svg";
import sendWhastapp from "../function/sendWhastapp";

const Facturacion = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validateEmail = (input) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(input);
  };

  const handleClick = () => {
    if (!email || !validateEmail(email)) {
      setError(true);
      setErrorMessage(
        !email
          ? "El campo de correo electrónico no puede estar vacío"
          : "Ingrese un correo electrónico válido",
      );
    } else {
      setError(false);
      sendWhastapp({
        message: `¡Hola!, quiero solicitar una facturación a este correo: ${email}`,
      });
      setEmail(""); // Limpia el campo de correo electrónico
    }
  };

  return (
    <MainDivTemplate bgStart="from-white" bgFinish="to-white" grid={true}>
      <div className="col-span-2 p-8 md:col-span-2 md:m-12">
        <div className="col-span-2 p-8 md:col-span-2 md:m-12">
          <Heading
            color="violet"
            size={{
              initial: "6",
              md: "9",
            }}
            mb="2"
          >
            Requisitos:
          </Heading>
          <p className="mb-2">
            Por favor tenga a la mano los siguientes datos y documentos para
            agilizar su facturación
          </p>
          <ul className="ml-4 list-disc font-bold ">
            <li>Foto del ticket a facturar</li>
            <li>Constancia fiscal en formato pdf</li>
            <li>Dirección de correo elctrónico</li>
          </ul>
          <img
            src={Billing}
            className="mx-auto w-[70%] md:w-[42%]"
            alt="factura"
          />
        </div>
      </div>
      <div className="sticky top-[10rem] p-8 md:m-12 md:my-auto">
        {error && (
          <Callout.Root mb="2" color="red">
            <Callout.Text>{errorMessage}</Callout.Text>
          </Callout.Root>
        )}
        <Heading color="violet" mb="2">
          Correo electrónico
        </Heading>
        <TextField.Root>
          <TextField.Input
            type="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            placeholder="email@ejemplo.com"
          />
        </TextField.Root>
        <Button mt="4" className="w-full" onClick={handleClick}>
          Facturar
        </Button>
      </div>
    </MainDivTemplate>
  );
};

export default Facturacion;
