import { Button, HoverCard } from "@radix-ui/themes";
import React from "react";
import { BsWhatsapp } from "react-icons/bs";
import sendWhastapp from "../function/sendWhastapp";

const FloatingWhastappButton = () => {
  return (
    <div className="sticky left-80 top-[35rem] md:left-[78rem] md:top-[25rem] z-50 flex w-12 h-12 justify-center rounded-full bg-white transition-all duration-200 hover:opacity-100 md:opacity-70">
      <HoverCard.Root>
        <HoverCard.Trigger>
          <button onClick={() => sendWhastapp("")} className="text-green-500 ">
            <BsWhatsapp className="p-1 font-bold md:p-2" size={50} />
          </button>
        </HoverCard.Trigger>
        <HoverCard.Content>
          <Button onClick={() => sendWhastapp("")}>Envia un whatsapp!</Button>
        </HoverCard.Content>
      </HoverCard.Root>
    </div>
  );
};

export default FloatingWhastappButton;
