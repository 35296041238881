import React from "react";
import { motion } from "framer-motion";

const MainDivTemplate = ({ children, bgStart, bgFinish, grid }) => {
  const gridStyle = `z-10 min-h-screen mb-16 mx-4 mt-28 rounded bg-gradient-to-br ${bgStart} ${bgFinish} md:mx-8 md:grid md:grid-cols-3`;
  const noGridStyle = `z-10 min-h-screen mb-16 mx-4 mt-28 rounded bg-gradient-to-br ${bgStart} ${bgFinish} flex flex-col gap-3`;

  if (grid)
    return (
      <motion.div
        initial={{ x:"-100vh" }}
        animate={{ x: 0 }}
        className={gridStyle}
      >
        {children}
      </motion.div>
    );
  else return <div className={noGridStyle}>{children}</div>;
};

export default MainDivTemplate;
