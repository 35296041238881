import React from "react";
import MainDivTemplate from "../elements/MainDivTemplate";
import { Heading, Strong, Text } from "@radix-ui/themes";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen">
      <MainDivTemplate
        bgStart="from-slate-100"
        bgFinish="to-slate-300"
        grid={false}
      >
        <div className="ml-4 flex flex-col gap-3 p-12">
          <Heading>
            <Strong>AVISO DE PRIVACIDAD INTEGRAL</Strong>
          </Heading>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <Strong>ROBERTO TIZNADO ZATARAIN,</Strong>
            <span>
              &nbsp;titular del Nombre Comercial Mariscos La Uni&oacute;n, con
              domicilio en&nbsp;
            </span>
            <Strong>
              Calle de Las Am&eacute;ricas, N&uacute;mero 20, Colonia Benito
              Ju&aacute;rez, C&oacute;digo Postal 82180, Municipio
              Mazatl&aacute;n, Estado Sinaloa, M&eacute;xico
            </Strong>
            <span>
              ; es el responsable del uso y protecci&oacute;n de sus datos
              personales, y al respecto le informamos lo siguiente:
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <Strong>
              &iquest;Para qu&eacute; fines utilizaremos sus datos personales?
            </Strong>
          </Text>
          <Text>
            <span>
              Los datos personales que recabamos de usted, los utilizaremos para
              las siguientes finalidades que son necesarias para el servicio que
              solicita:
            </span>
          </Text>
          <ul className="list-disc">
            <li>
              Prestar los servicios ofrecidos por Mariscos La Uni&oacute;n y que
              han sido aceptados al establecerse la relaci&oacute;n contractual;
            </li>
            <li>Gestionar tr&aacute;mites; y</li>
            <li>
              Contactar al Titular a trav&eacute;s de los medios que
              proporcione, para confirmar los datos personales necesarios para
              la ejecuci&oacute;n de la relaci&oacute;n contractual.
            </li>
          </ul>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              De manera adicional, utilizaremos su informaci&oacute;n personal
              para las siguientes finalidades que&nbsp;
            </span>
            <Strong>no son necesarias</Strong>
            <span>
              &nbsp;para el servicio solicitado, pero que nos permiten y
              facilitan brindarle una mejor atenci&oacute;n:
            </span>
          </Text>
          <ul className="list-disc">
            <li>
              <Strong>
                <em>Prospecci&oacute;n Comercial</em>
              </Strong>
            </li>
          </ul>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              En caso de que no desee que sus datos personales sean tratados
              para estos fines adicionales, desde este momento usted nos puede
              comunicar lo anterior, enviando un correo electr&oacute;nico a la
              direcci&oacute;n&nbsp;
            </span>
            <Strong>
              <u>administracion@mariscoslaunion.com.mx</u>
            </Strong>
            <Strong>,&nbsp;</Strong>
            <span>indic&aacute;ndonos lo siguiente:</span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <em>
              &ldquo;No consiento que mis datos personales se utilicen para las
              siguientes Finalidades secundarias:
            </em>
          </Text>
          <ul className="list-disc">
            <li>
              Prospecci&oacute;n comercial<em>&rdquo;</em>
            </li>
          </ul>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              La negativa para el uso de sus datos personales para estas
              finalidades no podr&aacute; ser un motivo para que le neguemos los
              servicios y productos que solicita o contrata con nosotros.
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <Strong>
              &iquest;Qu&eacute; datos personales utilizaremos para estos fines?
            </Strong>
          </Text>
          <Text>
            <span>
              Para llevar a cabo las finalidades descritas en el presente aviso
              de privacidad, utilizaremos los siguientes datos personales:&nbsp;
            </span>
          </Text>
          <ul className="list-disc">
            <li>Datos de identificaci&oacute;n; y</li>
            <li>Datos de contacto.</li>
          </ul>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <Strong>
              &iquest;Con qui&eacute;n compartimos su informaci&oacute;n
              personal y para qu&eacute; fines?
            </Strong>
          </Text>
          <Text>
            <span>
              Le informamos que sus datos personales son compartidos con
              personas, empresas, organizaciones y autoridades distintas a
              nosotros, &uacute;nicamente para cumplir el objeto de la
              relaci&oacute;n comercial.
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              Dado que estas transferencias se encuentran establecidas en el
              art&iacute;culo 37 de la Ley Federal de Protecci&oacute;n de Datos
              Personales en Posesi&oacute;n de los Particulares, no se requiere
              obtener un consentimiento adicional por parte de Usted.
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <Strong>
              &iquest;C&oacute;mo puede acceder, rectificar o cancelar sus datos
              personales, u oponerse a su uso?
            </Strong>
          </Text>
          <Text>
            <span>
              Usted tiene derecho a conocer qu&eacute; datos personales tenemos
              de usted, para qu&eacute; los utilizamos y las condiciones del uso
              que les damos (Acceso). Asimismo, es su derecho solicitar la
              correcci&oacute;n de su informaci&oacute;n personal en caso de que
              est&eacute; desactualizada, sea inexacta o incompleta
              (Rectificaci&oacute;n); que la eliminemos de nuestros registros o
              bases de datos cuando considere que la misma no est&aacute; siendo
              utilizada conforme a los principios, deberes y obligaciones
              previstas en la normativa (Cancelaci&oacute;n); as&iacute; como
              oponerse al uso de sus datos personales para fines
              espec&iacute;ficos (Oposici&oacute;n). Estos derechos se conocen
              como derechos ARCO.
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              Para el ejercicio de cualquiera de los derechos ARCO, usted
              deber&aacute; presentar la solicitud respectiva en el
              domicilio&nbsp;
            </span>
            <Strong>
              Calle de Las Am&eacute;ricas, N&uacute;mero 20, Colonia Benito
              Ju&aacute;rez, C&oacute;digo Postal 82180, Municipio
              Mazatl&aacute;n, Estado Sinaloa, M&eacute;xico,&nbsp;
            </Strong>
            <span>
              o bien, a trav&eacute;s del siguiente correo
              electr&oacute;nico&nbsp;
            </span>
            <Strong>
              <u>administracion@mariscoslaunion.com.mx</u>
            </Strong>
            <Strong>&nbsp;________</Strong>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              Para conocer el procedimiento y requisitos para el ejercicio de
              los derechos ARCO, usted podr&aacute; ponerse en contacto con
              nuestro Departamento de Privacidad, que dar&aacute; tr&aacute;mite
              a las solicitudes para el ejercicio de estos derechos, y
              atender&aacute; cualquier duda que pudiera tener respecto al
              tratamiento de su informaci&oacute;n. Los datos de contacto del
              Departamento de Privacidad son los siguientes:
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <Strong>
              <em>Correo electr&oacute;nico:&nbsp;</em>
            </Strong>
            <Strong>
              <em>
                <u>administracion@mariscoslaunion.com.mx</u>
              </em>
            </Strong>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <Strong>
              &iquest;C&oacute;mo puede revocar su consentimiento para el uso de
              sus datos personales?
            </Strong>
          </Text>
          <Text>
            <span>
              Usted puede revocar el consentimiento que, en su caso, nos haya
              otorgado para el tratamiento de sus datos personales. Sin embargo,
              es importante que tenga en cuenta que no en todos los casos
              podremos atender su solicitud o concluir el uso de forma
              inmediata, ya que es posible que por alguna obligaci&oacute;n
              legal requiramos seguir tratando sus datos personales. Asimismo,
              usted deber&aacute; considerar que, para ciertos fines, la
              revocaci&oacute;n de su consentimiento implicar&aacute; que no le
              podamos seguir prestando el servicio que nos solicit&oacute;, o la
              conclusi&oacute;n de su relaci&oacute;n con nosotros.
            </span>
          </Text>
          <Text>
            <span>
              Para revocar su consentimiento deber&aacute; presentar su
              solicitud mediante nuestro Departamento de Privacidad.
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              Para conocer el procedimiento y requisitos para la
              revocaci&oacute;n del consentimiento, usted podr&aacute; ponerse
              en contacto con nuestro Departamento de Privacidad.
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <Strong>
              &iquest;C&oacute;mo puede limitar el uso o divulgaci&oacute;n de
              su informaci&oacute;n personal?
            </Strong>
          </Text>
          <Text>
            <span>
              Con objeto de que usted pueda limitar el uso y divulgaci&oacute;n
              de su informaci&oacute;n personal, le ofrecemos los siguientes
              medios:
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <ul className="list-disc">
            <li>
              Su inscripci&oacute;n en el Registro P&uacute;blico para Evitar
              Publicidad, que est&aacute; a cargo de la Procuradur&iacute;a
              Federal del Consumidor, con la finalidad de que sus datos
              personales no sean utilizados para recibir publicidad o
              promociones de empresas de bienes o servicios. Para mayor
              informaci&oacute;n sobre este registro, usted puede consultar el
              portal de Internet de la PROFECO, o bien ponerse en contacto
              directo con &eacute;sta.
            </li>
          </ul>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <Strong>
              El uso de tecnolog&iacute;as de rastreo en nuestro portal de
              Internet
            </Strong>
          </Text>
          <Text>
            <span>
              Le informamos que en nuestra p&aacute;gina de Internet
              utilizamos&nbsp;
            </span>
            <em>cookies</em>
            <span>
              &nbsp;a trav&eacute;s de las cuales es posible monitorear su
              comportamiento como usuario de Internet, as&iacute; como brindarle
              un mejor servicio y experiencia de usuario al navegar en nuestra
              p&aacute;gina.
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              Los datos personales que obtenemos de estas tecnolog&iacute;as de
              rastreo son los siguientes:&nbsp;
            </span>
          </Text>
          <ul className="list-disc">
            <li>
              El n&uacute;mero de visitas al sitio web y la duraci&oacute;n de
              las mismas
            </li>
            <li>Las fuentes de tr&aacute;fico</li>
            <li>Sus p&aacute;ginas visitadas</li>
            <li>
              Tambi&eacute;n act&uacute;a sobre apartados como:
              <ul className="list-disc">
                <li>Sus secciones preferidas de nuestro sitio web</li>
                <li>
                  Keywords utilizadas (palabras utilizadas en sus
                  b&uacute;squedas)
                </li>
                <li>
                  Detalles t&eacute;cnicos de los dispositivos y navegadores a
                  trav&eacute;s de los cuales visita nuestro sitio web
                </li>
              </ul>
            </li>
          </ul>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              Lo anterior lo obtenemos mediante una herramienta de
              anal&iacute;tica y seguimiento de sitios web con fines
              estad&iacute;sticos y de mejora en el posicionamiento de este
              sitio web. Le informamos que no compartiremos estos datos.
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              Estas tecnolog&iacute;as podr&aacute;n deshabilitarse, de acuerdo
              con el navegador de su preferencia, siguiendo estos pasos:&nbsp;
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <Strong>
              <em>
                <u>Chrome</u>
              </em>
            </Strong>
          </Text>
          <ul className="list-disc">
            <li>
              Inicie Google Chrome y haga clic en los peque&ntilde;os tres
              puntos en la esquina superior derecha. Seleccione
              Configuraci&oacute;n.
            </li>
            <li>
              D&eacute; clic en la secci&oacute;n Privacidad y seguridad, busque
              la entrada Cookies y otros datos de sitios y p&uacute;lselo.
            </li>
            <li>
              Para deshabilitar las cookies, apague el interruptor junto a
              Permitir todas las cookies. A partir de ahora, las cookies
              est&aacute;n bloqueadas.
            </li>
          </ul>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              Cuando decida volver a habilitar las cookies, siga el mismo
              proceso para acceder a esta p&aacute;gina y simplemente vuelva a
              activar la funci&oacute;n. As&iacute; es&nbsp;como se
              aceptar&aacute;n cookies autom&aacute;ticamente.
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <Strong>
              <em>
                <u>Internet Explorer</u>
              </em>
            </Strong>
          </Text>
          <ul className="list-disc">
            <li>
              Inicie Internet Explorer. Haga clic en el peque&ntilde;o engranaje
              de la esquina superior derecha.
            </li>
            <li>Seleccione Opciones de Internet.</li>
            <li>
              Elija la pesta&ntilde;a Privacidad y haga clic en el bot&oacute;n
              Avanzada.
            </li>
            <li>
              En la siguiente pantalla, seleccione Bloquear, tanto de primera
              parte y&nbsp;Cookies de terceros. Por &uacute;ltimo, desmarque
              &ldquo;Permitir siempre las cookies de sesi&oacute;n&rdquo;.
            </li>
            <li>
              Haga clic en Aceptar, luego cierre las ventanas. A partir de
              ahora, las cookies de IE est&aacute;n desactivadas.
            </li>
          </ul>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              Para volver a habilitar las cookies en Internet Explorer, repita
              los pasos anteriores, pero esta vez seleccione las opciones
              Aceptar. Alternativamente, puede seleccionar la opci&oacute;n
              Preguntar; esto le indica a su navegador que le pida permiso para
              aceptar o rechazar cookies individualmente.
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <Strong>
              <em>
                <u>Microsoft Edge</u>
              </em>
            </Strong>
          </Text>
          <ul className="list-disc">
            <li>
              Abra Microsoft Edge y haga clic en los tres puntos en la esquina
              superior derecha.
            </li>
            <li>Haga clic en Configuraci&oacute;n.</li>
            <li>Haga clic en Cookies y permiso del sitio.</li>
            <li>
              Haga clic en Administra y elimina cookies y datos del sitio.
            </li>
            <li>
              Apague el interruptor junto a Permitir que los sitios guarden y
              lean datos de cookies (recomendado).
            </li>
          </ul>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              Cuando desee volver a habilitar las cookies, simplemente haga clic
              en el mismo interruptor para activarlo.
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <Strong>
              <em>
                <u>Safari</u>
              </em>
            </Strong>
          </Text>
          <ul className="list-disc">
            <li>
              Inicie Safari. Haga clic en Safari en la esquina superior
              izquierda de su men&uacute; principal.
            </li>
            <li>Seleccione Preferencias.</li>
            <li>
              Haga clic en Privacidad y seleccione Bloquear todas las cookies.
            </li>
            <li>Confirme el mensaje de advertencia.</li>
          </ul>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              Para volver a habilitar las cookies en Safari, siga las mismas
              instrucciones. Luego, desmarque la opci&oacute;n Bloquear todas
              las cookies.
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>
              Para mayor informaci&oacute;n sobre el uso de estas
              tecnolog&iacute;as, puede consultar los siguientes sitios de
              Internet:
            </span>
          </Text>
          <ul className="list-disc">
            <li>
              <u>
                <a href="https://support.google.com/accounts/answer/61416?hl=ES">
                  https://support.google.com/accounts/answer/61416?hl=ES
                </a>
              </u>
            </li>
            <li>
              <u>
                <a href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
                  https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d
                </a>
              </u>
            </li>
            <li>
              <u>
                <a href="https://support.apple.com/es-mx/guide/safari/sfri11471/mac">
                  https://support.apple.com/es-mx/guide/safari/sfri11471/mac
                </a>
              </u>
            </li>
          </ul>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <Strong>
              &iquest;C&oacute;mo puede conocer los cambios a este aviso de
              privacidad?
            </Strong>
          </Text>
          <Text>
            <span>
              El presente aviso de privacidad puede sufrir modificaciones,
              cambios o actualizaciones derivadas de nuevos requerimientos
              legales; de nuestras propias necesidades por los productos o
              servicios que ofrecemos; de nuestras pr&aacute;cticas de
              privacidad; de cambios en nuestro modelo de negocio, o por otras
              causas.
            </span>
          </Text>
          <Text>
            <span>
              Nos comprometemos a mantenerlo informado sobre los cambios que
              pueda sufrir el presente aviso de privacidad, a trav&eacute;s
              de&nbsp;nuestro sitio web.
            </span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>&nbsp;</span>
          </Text>
          <Text>
            <span>&Uacute;ltima actualizaci&oacute;n&nbsp;</span>
            <Strong>03 de octubre del 2022</Strong>
          </Text>
        </div>
      </MainDivTemplate>
    </div>
  );
};

export default PrivacyPolicy;
