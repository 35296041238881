import {Button, Text } from "@radix-ui/themes";
import React from "react";
import { FaMapMarkerAlt } from "react-icons/fa";
import {BsWhatsapp, BsMessenger} from "react-icons/bs"
import sendMessenger from '../function/sendMessenger'
import sendWhatsapp from '../function/sendWhastapp'

const SecondaryContent = () => {
  return (
    <div>
      <div className="flex items-center justify-center gap-2 md:justify-end">
        <Text size="7" color="violet">
          Estamos aquí
        </Text>
        <Text color="violet">
          <FaMapMarkerAlt size={30} />
        </Text>
      </div>
      <div className="mt-5 text-center md:text-right">
        <Text size="4">
          <a
            href="https://maps.app.goo.gl/hUAiAjP8XACXLtw37"
            target="blank"
            aria-label="mapa"
            className="hover:underline"
          >
            Av. Revolución 5113, Benito Juárez, 82180 Mazatlán, Sin.
          </a>
        </Text>
      </div>
      <div className="mt-14 flex flex-col gap-5 text-center md:text-right">
        <Text size="7" color="violet">
          ¿Te lo llevamos?
        </Text>
        <Text>
          Pídelo a domiclio al{" "}
          <Text weight='bold' color="violet">
            <a href="tel:6691938973" target="_blank" rel="noreferrer">
              6691938973
            </a>
          </Text>
          , o bien, en las siguientes opciones:
        </Text>
        <div className="flex flex-col gap-4">
            <Button onClick={() => sendWhatsapp({message: "¡Hola! quiero hacer un pedido a domicilio"})} color="green">WhatsApp<BsWhatsapp /></Button>
            <Button onClick={() => sendMessenger({message: "¡Hola! quiero hacer un pedido a domicilio"})} color="blue">Messenger<BsMessenger /></Button>
        </div>
        <div>
          <Text size='3'>Psssst... también <Text as='span' weight='bold' color="violet">puedes reservar tu mesa ;) </Text></Text>
        </div>
      </div>
    </div>
  );
};

export default SecondaryContent;
