import { Heading, Text } from "@radix-ui/themes";
import ZonaKids from "./../images/zona_kids.jpg";


const MainContent = () => {
  return (
    <div className=" border-b-2 md:border-b-0 md:border-r-2">
      <Heading
        size={{
          initial: "6",
          sm: "9",
        }}
        align="center"
        color="violet"
        mb="5"
      >
        ¡Ven a pasar un rato agradable!
      </Heading>
      <div className="grid md:grid-cols-2 justify-center items-center">
        <div className="col-span-1 mr-4 overflow-hidden rounded-full shadow-xl">
          <img src={ZonaKids} alt="zona_kids" />
        </div>
        <div className="col-span-1 mt-6 md:mt-0">
          <Text
            size={{
              initial: "2",
              sm: "5",
            }}
          >
            Disfruta de los mejores platillos del mar, como <Text as='span' weight='bold' color="violet">cevices, aguachiles,
            cocteles y ¡mucho más! </Text> en un ambiente 100% familiar, además los más
            pequeños pasarán momentos divertidos en nuestra <Text as='span' weight='bold' color="violet">area de juegos "Zona
            Kids".</Text>
          </Text>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
