import emailjs from "@emailjs/browser";
import { Callout, Flex, Text, TextArea, TextField } from "@radix-ui/themes";
import React, { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const ContactForm = () => {
  const [submitting, setSubmitting] = useState(false);
  const [bgColor, setBgColor] = useState("bg-violet-600");
  const [sucess, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const buttonStyle = `${bgColor} text-white rounded py-1`;
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      user_name: "",
      user_email: "",
      message: "",
    },
  });

  const fieldErrorMessage = (field) => {
    return (
      <Callout.Root mt="2" color="red">
        <Callout.Text>
          {field === "user_name" ? "El nombre es requerido" : null}
          {field === "user_email" ? "El correo es requerido" : null}
          {field === "message" ? "Un mensaje es requerido" : null}
        </Callout.Text>
      </Callout.Root>
    );
  };

  const successMessage = (
    <Callout.Root mt="2" color="green">
      <Callout.Text>Mensaje enviado con éxito</Callout.Text>
    </Callout.Root>
  );
  const formErrorMessage = (
    <Callout.Root mt="2" color="red">
      <Callout.Text>Servicio temporalmente fuera de servicio, use otro método de contact</Callout.Text>
    </Callout.Root>
  );

  const formRef = useRef();

  const onSubmit = async () => {
    setSubmitting(true);
    setBgColor("bg-violet-200");
    try {
      await emailjs.sendForm(
        "service_hgteyag",
        "template_6gidz3f",
        formRef.current,
        "mX1nzI-ns9SXzyUTe",
      );
      setSubmitting(false);
      setBgColor("bg-violet-700");
      reset();
      setSuccess(true);
    } catch (error) {
      setError(true);
    }
  };
  return (
    <>
    {sucess && successMessage}
    {error && formErrorMessage}
      <form ref={formRef} id="contactForm" onSubmit={handleSubmit(onSubmit)}>
        <Flex
          mb={{
            initial: "2",
          }}
          direction={{
            initial: "column",
            sm: "row",
          }}
          gap={{
            initial: "3",
            sm: "9",
          }}
        >
          <div className="flex flex-col gap-2">
            <Text htmlFor="user">Nombre * :</Text>
            <Controller
              name="user_name"
              control={control}
              defaultValue=""
              rules={{ required: "El nombre es requerido" }}
              render={({ field }) => (
                <div>
                  <TextField.Root>
                    <TextField.Input
                      name="user_name"
                      placeholder="Ponga su nombre aquí"
                      {...field}
                    />
                  </TextField.Root>
                  {errors.user_name && fieldErrorMessage("user_name")}
                </div>
              )}
            />
          </div>

          <div className="flex flex-col gap-2">
            <Text>Correo * :</Text>
            <Controller
              name="user_email"
              control={control}
              defaultValue=""
              rules={{ required: "El email es requerido" }}
              render={({ field }) => (
                <div>
                  <TextField.Root>
                    <TextField.Input
                      name="user_email"
                      placeholder="ejemplo@ejemplo.com"
                      {...field}
                      type="email"
                    />
                  </TextField.Root>
                  {errors.user_email && fieldErrorMessage("user_email")}
                </div>
              )}
            />
          </div>
        </Flex>
        <div className="mt-4 flex flex-col gap-3 md:w-[70%]">
          <Text>Mensaje * :</Text>
          <Controller
            name="message"
            control={control}
            defaultValue=""
            rules={{ required: "Escriba un mensaje" }}
            render={({ field }) => (
              <div>
                <TextArea
                  name="message"
                  placeholder="Escriba aquí su mensaje"
                  {...field}
                />
                {errors.message && fieldErrorMessage("message")}
              </div>
            )}
          />

          <button disabled={submitting} className={buttonStyle} type="submit">
            {submitting ? "Enviando..." : "Enviar"}
          </button>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
