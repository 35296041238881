import React from "react";
import { Text } from "@radix-ui/themes";
import FooterSocials from "../elements/FooterSocials";
import { AiFillHeart } from "react-icons/ai";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className=" mt-auto grid w-full items-center justify-center bg-gradient-to-b from-slate-700 to-slate-900 text-white md:grid-cols-3">
      <div className="col-span-1 flex h-[85%] flex-col gap-2 border-b-2 border-white px-2 py-2 text-center md:border-b-0 md:border-r-2 md:py-4">
        <Text
          size={{
            initial: "1",
            sm: "2",
          }}
        >
          Mariscos La Unión, Todos los derechos reservados 2023-2024 
        </Text>
        <Text mb='4'
          size={{
            initial: "1",
            sm: "2",
          }}
        >
          <a
            href="https://maps.app.goo.gl/hUAiAjP8XACXLtw37"
            target="blank"
            aria-label="mapa"
            className="hover:underline"
          >
            Av. Revolución 5113, Benito Juárez, 82180 Mazatlán, Sin.
          </a>
          <br/> <Link 
          className="text-yellow-300 underline" 
          to="/aviso_de_privacidad"
          onClick={() => window.scroll(0,0)}
          >Aviso de privacidad</Link>
        </Text>
      </div>
      <div className="col-span-1 flex h-[85%] flex-col gap-2 border-b-2 border-white px-2 pb-8 pt-2 md:border-b-0 md:border-r-2 md:py-8">
        <FooterSocials />
      </div>
      <div className="col-span-1 flex h-[85%] flex-col gap-2  px-2 py-4 text-center md:py-8">
        <Text
          size={{
            initial: "1",
            sm: "2",
          }}
        >
          <div className="flex items-center justify-center gap-2">
            Hecho con <AiFillHeart /> por @wlbApps
          </div>
        </Text>
      </div>
    </div>
  );
};

export default Footer;
