import React from 'react'
import { DropdownMenu, Button, Text } from '@radix-ui/themes';
import { AiFillCaretDown } from 'react-icons/ai';

const Categories = ({selectedCategory, setCategory}) => {
    const categories = [
        {
          name: "Barra Fría",
        },
        {
          name: "Barra Caliente",
        },
        {
          name: "Entradas",
        },
        {
          name: "Bebidas",
        },
      ]; 
  return (
    <DropdownMenu.Root>
            <DropdownMenu.Trigger color="violet">
              <Button variant="outline" size="4">
                {selectedCategory === "" ? (
                  <Text>Selecciona una opción</Text>
                ) : (
                  <Text>{selectedCategory}</Text>
                )}
                <AiFillCaretDown />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content variant="solid">
              {categories.map((categorie) => {
                return (
                  <DropdownMenu.Item
                    key={categorie.name}
                    textValue={categorie.name}
                    onClick={() => setCategory(categorie.name)}
                  >
                    {categorie.name}
                  </DropdownMenu.Item>
                );
              })}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
  )
}

export default Categories
